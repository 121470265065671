
.modal-content {
  width: 100%;
  border-radius: 0;
}

.modal-body {
  max-height: 600px;
  padding: 1rem;
  overflow: auto;

  .sub-title {
    margin-bottom: 1rem;
  }
}

.modal-footer {
  padding: .75rem;
}
