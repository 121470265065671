
.page-title-wrapper {
  padding-top: 6px;

  @media screen and (max-width: 767.98px) {
    padding: 0;
  }
}

.page-title {
  line-height: 120%;
  font-weight: 700;
  margin: 0;
}

.page-title-widget {
  display: flex;
  justify-content: space-between;
  line-height: 120%;

  .enlarged & {
    padding-left: 36px;

    @media screen and (max-width: 767.98px) {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 767.98px) {
    padding: 0 0 0 36px;
  }

  /deep/ .form-group {
    margin-bottom: 0;
    align-self: start;
  }
}

.button-menu-mobile {
  border: none;
  color: #6e768e;
  width: auto;
  padding: 0;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  margin-right: 16px;
  display: none;

  @media screen and (max-width: 1023.98px) {
    display: block;
    color: #6e768e;
  }

  &.hide {
    display: none;
  }
}
